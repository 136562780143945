<template>
  <component
    :is="PaymentFormAdornment"
    v-if="PaymentFormAdornment"
    v-bind="props"
  >
    <slot />
  </component>
  <slot v-else />
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { usePaymentFormVersion } from '../../../form-common/composables/usePaymentFormVersion'

import type { PaymentFormAdornmentProps } from './PaymentFormAdornment.type'
import AffirmPaymentFormAdornment from './components/AffirmPaymentFormAdornment.vue'
import KlarnaPaymentFormAdornment from './components/KlarnaPaymentFormAdornment.vue'
import LegacyPaymentFormAdornment from './components/LegacyPaymentFormAdornment.vue'
import PaypalPaymentFormAdornment from './components/PaypalPaymentFormAdornment.vue'
import SquarePaymentFormAdornment from './components/SquarePaymentFormAdornment.vue'

const props = defineProps<PaymentFormAdornmentProps>()

const formVersion = usePaymentFormVersion()
const PaymentFormAdornment = computed(() => {
  if (formVersion === 'v1') {
    return LegacyPaymentFormAdornment
  }

  switch (props.paymentMethod?.bmCode) {
    case 'paypal':
      return PaypalPaymentFormAdornment
    case 'klarna_pay_later':
    case 'klarna_slice_it':
      return KlarnaPaymentFormAdornment
    case 'clearpay':
    case 'afterpay':
      return SquarePaymentFormAdornment
    case 'affirm':
      return AffirmPaymentFormAdornment
    default:
      return null
  }
})
</script>
