import type { PaymentMethod } from '@backmarket/http-api/src/api-specs-payment/payment'

import { isValidKlarnaPaymentMethodConfig } from '../../form-common/helpers/isValidConfig'

import { useScript } from './useScript'

// TODO, merge this with the other `useKlarnaLibrary`
export const useKlarnaLibrary = (paymentMethod: PaymentMethod) => {
  if (!isValidKlarnaPaymentMethodConfig(paymentMethod.config)) {
    throw new Error(
      'Invalid Klarna payment method config. Missing clientId or environment',
    )
  }

  const { environment, clientId } = paymentMethod.config

  return useScript({
    // TODO[PAYIN-4221]: Use libraryUrl when it will be migrated to the new version
    src: 'https://js.klarna.com/web-sdk/v1/klarna.js',
    'data-environment': environment,
    'data-client-id': clientId,
  })
}
